<script>
import serverConfig   from '@/helpers/config';
import Swal from "sweetalert2";
import axios from 'axios';
import { ref } from "vue";
import { useCookies } from "vue3-cookies";
import moment from 'moment';
import { mapActions, mapGetters} from "vuex";


export default {
  name: "TableArchiveByCustomerProject.vue",
  props: [
    'dirBase',
    'tokenizer',
    'type',
    'title',
  ],
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    const { cookies } = useCookies();    
    let tk = 'Bearer '+ localStorage.getItem('tk')

    return { active, toggleActive,tk,cookies };
  },
  data() {
	return {
          UrlServer: serverConfig.EP,
          datastore: this.$store.state,
          userData: this.$store.state.auth.currentUser,
          exportLabelsButton:false,
          formCheckSynchro:false,
          currentYear:new Date().getFullYear(),
          filterByYear:new Date().getFullYear(),
          years:[],
          fileName:'PROVA',
          page: 1,
          perPage: 20,
          sortRes:'asc',
          value: 'All',
          pages: [],
          dbgruppo:'nd',
          paginated:{},
          paginateSettings:false,
          synchroESAction:false,
          refreshTitle:'',
          refreshMsg:'',
          select:'all',
          search:'',
          responseList:[],
          responseListES:false,
          checkPrevSyncValue:false,
          responseGroupFieldList:[],
          n_results:0,
          salesRouteList:[],
          canali:[],
          synchroForce:false,
          item:{
            um:"",
            um2:"",
            Famiglia:"",
            Tipologia:"",
            EAN:"",
            CodArt:"",
            Nome:"",
            slug:"",
            keywords:"",
            tags:"",
            Descrizione:"",
            quantity:0,
            quantity_2:0,
            CodFamiglia:"",
            CodFAM: "",
            CodContoAcquisto:"",
            CodContoVendita: "",
            Produttore: "",
            CodMarca: "",
          },
          filterBy:"-",
          filterByValue:"-",
          base64Str:null,
          decodeBase64:null,
          fileList:[],
        }
	},
	computed:{ 
    ...mapGetters('auth', ['getSR', ])
	},
  mounted(){
    
    if ( this.$route.query.search && this.$route.query.page){
      this.search=this.$route.query.search
      this.page=this.$route.query.page
      this.getFilter()
    }
    this.years=[]
    for (let i = 2008; i < this.currentYear; i++) {
      this.years.push(i);
    }
    this.years.push(this.currentYear)
    this.years.reverse();
  },
	methods:{
    ...mapActions('auth', ['getSalesRoute', ]),
    ...mapActions('ecommercesettings', ['addToCart', 'getCartByOrd']),
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          response.meta = response.meta || {}
          response.meta.requestStartedAt = new Date().getTime();
      
          return response;
        },
        (error) => {
          console.log('Error interceptors - archive')
              console.log(error)
              if (error=='Error: Network Error') {
                Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2500,
                  })
                
                  if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                  }
                  this.$router.push('/login')
                      
              } else {
              
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                    this.$router.push('/')             
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }
                
              }

        }
      );
      
    },
    convertDatetime(value){
        if (value) {
            return moment(value).format('LLL')
        }
    },
    roundTo(value, decimalpositions)
    {
      var i = value * Math.pow(10,decimalpositions);
      i = Math.round(i);
      return i / Math.pow(10,decimalpositions);
    },
    formatPrice(value) {
      if (value) {
        let val = (value/1).toFixed(2).replace('.',',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      } else {
        return 'n.d.'
      }

    },
    replName(val,el){
      if (val.includes(el)){
        return val.replaceAll(el,' ')
      } else {
        return val
      }
    },
    formatKb(val){
      if (val){
        return this.roundTo(val / 1024, 1)
      } else {
        return ''
      }
    },
    getParent(){
      //this.getSearchList()
      this.getFilter()
    },
    resetQuery(){
      this.resetQueryString()
      this.page = 1
      this.responseList = []
    },
    synchroApp() {
        Swal.fire(
          {
            title: this.$t("t-wa-sync-confirm-setting-title"),
            text: this.$t("t-wa-sync-confirm-setting-text"),
            icon: this.$t("t-wa-sync-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-wa-sync-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-wa-sync-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-wa-sync-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-wa-sync-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
          if (result.isConfirmed) {
            //this.sendItemsInES()
            this.synchroDirectory()
          }
        });
    },
    searchByFolder(query){
        this.search = query
        this.getFilter()
    },
    getFilter(){
      
      //if (this.filterBy!="-") {
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}llm/by/tokenizer`,
            { 
                perPage: this.perPage,
                page: this.page,
                sortRes: this.sortRes,
                filterBy: this.tokenizer,
                filterByYear: 2025,
                search: this.search,
            },  
            { 
                headers: { 'authorization':this.tk},
            },

        ).then( response => {
          if(response.data){
            this.responseList = response.data.results.data;
            this.paginated = response.data.results
            this.n_results = response.data.n_results
          }
        })
      /*
        } else {
          Swal.fire({
            title:"Area di riferimento mancante",
            text:"Attenzione, prima di avviare la sincronizzazione delle directory dovresti indicare l'area di riferimento:'Lavori Pubblici', 'Aziende' o 'Privati'",
            icon:"warning",
            showCancelButton:false,
            showConfirmButton:false,
            timer:2000,
          })
        }
      */
    },

    checkItemsInES(){
      
      axios.post(`${this.UrlServer}esync/check-item-sync`,{items:this.responseList}, 
        { 
          headers: { 'authorization':this.tk},
        },
       ).then( response => {
         console.log(response)
          if (response.data) {
            this.responseListES = response.data;
            let obj = JSON.stringify({
              "filterBy": this.filterBy,
              "filterByValue": this.filterByValue,
              "search": this.search,
              "existentData": this.responseListES.existentData,
              "nonExistentData": this.responseListES.nonExistentData,
              "status": this.responseListES.status,
            })
            let base64Str  = Buffer.from(obj).toString('base64')
            localStorage.setItem('checkitemssync', base64Str)
            //this.checkPrevSync()
            if ( this.responseListES.nonExistentData.length > 0 )
            {
              this.synchroApp() 
            }
          }
      })
    },
    setSyncroField(){
      /*
      let configEs = atob(localStorage.getItem('itemsines'))
      let objConfigEs = JSON.parse(configEs)
      if (objConfigEs) {
        if(Object.keys(objConfigEs).length>0) {
          this.search = objConfigEs.search
          this.filterBy = objConfigEs.filterBy
          this.filterByValue = objConfigEs.filterByValue
          this.page = objConfigEs.page
          this.perPage = objConfigEs.perPage
          this.getGroupField()
          this.responseList = []
          this.getFilteredList()
        }
      }
      */
      let refList = this.responseList
      let listA = []
      for (let item of refList){
        item.syncES = true
        listA.push(item)
      }
      this.responseList = []
      this.responseList = listA
    },
    sendItemsInES(){

          axios.post(`${this.UrlServer}llm/post/filter`,{
              perPage: this.perPage,
              page: this.page,
              filterBy: this.filterBy,
              search: this.search,
            }, 
            { 
              headers: { 'authorization':this.tk},
              onUploadProgress: (event) =>
                {
                  const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                
                  let timerInterval;
                  Swal.fire({
                    title: "Caricamento...",
                    html: "Progresso operazione <b></b>.",
                    timer: percentUploaded,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();
  
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    }
                  }).then((result) => {
                    
                    if (result.dismiss === Swal.DismissReason.timer) {
                      Swal.fire({
                        title:"Inizio operazione",
                        text:"Ricerca avviata, attendi per favore...",
                      
                        showCancelButton:false,
                        showConfirmButton:false,
                        //timer:3000,
                      })
                    }
                  });

                },
                onDownloadProgress: (event) =>
                {
                  const percentDownloaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                
                  let timerInterval;
                  Swal.fire({
                    title: "Download data",
                    timer: percentDownloaded,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();
                      /*
                        const timer = Swal.getPopup().querySelector("b");

                        timerInterval = setInterval(() => {
                          timer.textContent = `Operazione conclusa`;
                        }, 100);
                      */
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    }
                  }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                      Swal.fire({
                        title:"Esito operazione",
                        text:"La ricerca si è conclusa con successo",
                        icon:"success",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:2000,
                      })
                    }
                  });

                }
            },
            
          ).then( response => {
            this.responseList = response.data.results.data;
         
            this.paginated = response.data.results
            this.n_results = response.data.results.length
            
          })
        
    },
    synchroDirectory(){
     
      if (this.filterBy!="-") {
        this.axiosInterceptor()
        let timing = 0
        axios.post(`${this.UrlServer}llm/process-directory`,
        { 
          directory:this.filterBy,
          year: this.filterByYear,
        },  
        { 
            headers: { 'authorization':this.tk},
            onUploadProgress: (event) =>
              {
                timing = new Date().getTime()
                console.log("timing up")
                console.log(timing)
                console.log("event");
                console.log(event)
                const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                console.log(percentUploaded)

                let timerInterval;                
                Swal.fire({
                  title: "Caricamento...",
                  html: "Progresso operazione <b></b>.",
                  timer: timing,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                    const timer = Swal.getPopup().querySelector("b");
                    timerInterval = setInterval(() => {
                      timer.textContent = `${Swal.getTimerLeft()}`;
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                    timing=0
                  }
                }).then((result) => {
                  
                  if (result.dismiss === Swal.DismissReason.timer) {
                    setInterval(() => {
                      timing += 100;
                      console.log(timing)
                    }, 100);
                    Swal.fire({
                      title:"Inizio operazione",
                      text:"Sincronizzazione avviata, attendi per favore...",
                     // timer: percentUploaded,
                      timerProgressBar: true,
                      showCancelButton:false,
                      showConfirmButton:false,
                      //timer:3000,
                    })
                  }
                });
                
              },
              onDownloadProgress: (event) =>
              {
                timing=0
                console.log("timing down")
                
                console.log(new Date().getTime())
                console.log("event download");
                console.log(event)
                const percentDownloaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                console.log(percentDownloaded)
                console.log('timing')
                console.log(timing)

                let timerInterval;
                Swal.fire({
                  title: "Download data",
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                    /*
                      const timer = Swal.getPopup().querySelector("b");

                      timerInterval = setInterval(() => {
                        timer.textContent = `Operazione conclusa`;
                      }, 100);
                    */
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  }
                }).then((result) => {
                  if (result.dismiss === Swal.DismissReason.timer) {
                    Swal.fire({
                      title:"Esito operazione",
                      text:"La sincronizzazione si è conclusa con successo",
                      icon:"success",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                  }
                });

              }
          },
          
        ).then( response => {
          if(response.data){
            if (response.data.status=='OK') {
              this.getSynchronizedDirectory();
            }
          }
        })
      } else {
        Swal.fire({
          title:"Area di riferimento mancante",
          text:"Attenzione, prima di avviare la sincronizzazione delle directory dovresti indicare l'area di riferimento:'Lavori Pubblici', 'Aziende' o 'Privati'",
          icon:"warning",
          showCancelButton:false,
          showConfirmButton:false,
          timer:2000,
        })
      }
    },
    getSynchronizedDirectory(){
     
    // if (this.filterBy!="-") {
       axios.post(`${this.UrlServer}llm/synchronized/directory`,{
            perPage: this.perPage,
            page: this.page,
            directory:this.filterBy,
            year: this.filterByYear,
            search: this.search,
         }, 
         { 
           headers: { 'authorization':this.tk},
           onUploadProgress: (event) =>
             {
               const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
             
               let timerInterval;
               Swal.fire({
                 title: "Caricamento...",
                 html: "Progresso operazione <b></b>.",
                 timer: percentUploaded,
                 timerProgressBar: true,
                 didOpen: () => {
                   Swal.showLoading();

                 },
                 willClose: () => {
                   clearInterval(timerInterval);
                 }
               }).then((result) => {
                 
                 if (result.dismiss === Swal.DismissReason.timer) {
                   Swal.fire({
                     title:"Inizio operazione",
                     text:"Ricerca avviata, attendi per favore...",
                   
                     showCancelButton:false,
                     showConfirmButton:false,
                     //timer:3000,
                   })
                 }
               });

             },
             onDownloadProgress: (event) =>
             {
               const percentDownloaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
             
               let timerInterval;
               Swal.fire({
                 title: "Download data",
                 timer: percentDownloaded,
                 timerProgressBar: true,
                 didOpen: () => {
                   Swal.showLoading();
                   /*
                     const timer = Swal.getPopup().querySelector("b");

                     timerInterval = setInterval(() => {
                       timer.textContent = `Operazione conclusa`;
                     }, 100);
                   */
                 },
                 willClose: () => {
                   clearInterval(timerInterval);
                 }
               }).then((result) => {
                 if (result.dismiss === Swal.DismissReason.timer) {
                   Swal.fire({
                     title:"Esito operazione",
                     text:"La ricerca si è conclusa con successo",
                     icon:"success",
                     showCancelButton:false,
                     showConfirmButton:false,
                     timer:2000,
                   })
                 }
               });

             }
         },
         
       ).then( response => {
         this.responseList = response.data.results.data;

         this.paginated = response.data.results
         this.n_results = response.data.results.length
         
       })
    // }
   },
    checkPrevSync(){
      this.checkPrevSyncValue = atob(localStorage.getItem('checkitemssync'))
      let obj = JSON.parse(this.checkPrevSyncValue)
      if (this.checkPrevSyncValue) {
        if (obj.filterBy == this.filterBy && obj.filterByValue==this.filterByValue && obj.search == this.search) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    resetDBGruppo(){
      this.paginateSettings=true 
      this.synchroESAction=true      
    },
    getFilter2(){
    
       this.responseList = []      
       this.axiosInterceptor()
      if (this.filterBy!='-') {

       axios.post(`${this.UrlServer}llm/search-wfilter`, 
       { 
             perPage: this.perPage,
             page: this.page,
             sortRes: this.sortRes,
             filterBy: this.filterBy,
             filterByYear: this.filterByYear,
             search: this.search,
            
        },  
        { 
           headers: { 'authorization':this.tk},
           onUploadProgress: (event) =>
                {
                  const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                
                  let timerInterval;
                  Swal.fire({
                    title: "Caricamento...",
                    html: "Progresso operazione <b></b>.",
                    timer: percentUploaded,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    }
                  }).then((result) => {
                    
                    if (result.dismiss === Swal.DismissReason.timer) {
                      Swal.fire({
                        title:"Inizio operazione",
                        text:"Ricerca avviata",
                      
                        showCancelButton:false,
                        showConfirmButton:false,
                        //timer:3000,
                      })
                    }
                  });

           },
           
           onDownloadProgress: (event) =>
                {
                  const percentDownloaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                
                  let timerInterval;
                  Swal.fire({
                    title: "Download data",
                    timer: percentDownloaded,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();

                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    }
                  }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                     console.log('Ricerca conclusa.')
                    }
                  });

           }
                 
         }
       ).then( response => {
         
          this.responseList = response.data.results.data;
         
          this.paginated = response.data.results
          this.n_results = response.data.n_results
          this.createQueryString()
       })
               
      }
    },
    exportLabels(){
      if (this.filterByValue!='-'){ 
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}docugen/download-product-code/${this.base64Str}`, 
          {  
            headers: { 'authorization':this.tk}
          }
        ).then( response => {
         
          if (response.headers) {
            if (response.headers["content-type"]=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
             
              Swal.fire({
                title:"Esportazione file avvenuta",
                text:"Esportazione avvenuta con succcesso. Puoi trovare il file excel esportato tra i tuoi download...",
                icon:"success",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2500,
              })
            }
          }
        })
      }
      
    },
    getGroupField(){
      if (this.filterBy!='-'){
       let url = `${this.UrlServer}llm/group/by`
        /* 
        if (this.filterBy == 'CodArt'){
          this.responseGroupFieldList = []
          Swal.fire({
                title:"Richiesto Codice Articolo",
                text:"Inserisci il Codice Articolo, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:3500,
              })
              window.stop();
        } else if (this.filterBy=='Nome'){
          
          this.responseGroupFieldList = []
        } else if (this.filterBy=='CodFAM'){
          url = `${this.UrlServer}categories/list`
          this.filterGroup(url)
        }else if (this.filterBy=='CodMarca'){
          url = `${this.UrlServer}brands/list`
          this.filterGroup(url)
        }
        */
        this.filterGroup(url)
      }

    },
    filterGroup(url){
      this.axiosInterceptor()
      axios.get(url, 
            { 
                params: { 
                    field:this.filterBy,
                }, 
                headers: { 'authorization':this.tk}
            }
          ).then( response => {
            if (response.data){
              this.responseGroupFieldList = response.data
            }
            
      })
    },
    paginateAct(pg){
      this.page = pg
      //this.getSearchList()
      this.paginateSettings = true
      
      this.getFilter()
      
    },
    initItem(){
      this.axiosInterceptor()
      axios.post(`${this.UrlServer}products/register`,this.item, {headers: { authorization:this.tk}} )
        .then((response)=>{
          this.$router.push(`/warehouse/edit-product/${response.data._id}`)
        })
    },
    getFilePath(filePath,index) {
        
      this.axiosInterceptor();
      axios.get(`${this.UrlServer}llm/path/signed/url`, 
        { 
          params: { 
            filepath: filePath,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
         if (response.data){
             //window.location.href=response.data
            window.open(response.data, '_blank');
            this.responseList[index].resp = response.data
          } else {
            this.responseList[index].resp = false
          }
       });
    
      
    },
    replAll(str,val1,val2){
      if (str){
        return str.replaceAll( val1, val2)
      } else {
        return '-'
      }
    },
    createQueryString(){
      this.$router.push(this.$route.path+`?search=${this.search}&page=${this.page}&filterBy=${this.filterBy}&filterByYear=${this.filterByYear}`)
    },
    resetQueryString(){
      this.$router.push(this.$route.path)
    },
    extractFilename(filename){
        let dir = filename.slice(0,filename.lastIndexOf('/') + 1);
        let spl = filename.split(dir)
        if (spl.length>1){
            return spl[1] 
        }
    },
    extractBaseDir(filename){
        
        let spl = filename.split(this.dirBase)
        if (spl.length>1){
            return spl[1] 
        }
    },
    viewPath(filename) {
        let file = filename.slice(0,filename.lastIndexOf('/') + 1);
        let splExcBase = file.split(this.dirBase)
        let subDir = splExcBase[1].split('/')

        return subDir 
    },
    countCharInString(str, char) {
        let count = 0;
        for (let i = 0; i < str.length; i++) {
            if (str.charAt(i) == char) {
                count += 1;
            }
        }
        return count;
    }
    
  },
  components: {
    
  },
};
</script>
<template>
  <div class="back-grey">
    <div class="card">
      <div class="card-header border-bottom-dashed mb-20">
            <div class="row g-4 align-items-center">
              <div class="col-sm">
                <div>
                
                  <h5 class="card-title mb-0">Ricerca archivio</h5>
                
                </div>
              </div>
              <div class="col-sm-auto">
                <div class="form-check mb-3">
                    <a href="javascript:void(0)" @click="getFilter()">Visualizza</a>
                <!--
                    <input class="form-check-input" type="checkbox" id="formCheckSynchro" v-model="formCheckSynchro">
                  <label class="form-check-label" for="formCheckSynchro">
                    Forza sincronizzazione NAS-APP
                  </label>
                -->
                </div>      
              </div>
              <div class="col-sm-auto"  v-if="formCheckSynchro">
                <div class="form-check mb-3">
                  <select class="form-select" v-model="filterBy">
                    <option value="-"> Filtra per area:</option>
                    <option value="Aziende">Aziende</option>
                    <option value="Lavori Pubblici">Lavori Pubblici</option>
                    <option value="Privati">Privati</option>
                  </select>
                </div>      
              </div>
              <div class="col-sm-auto"  v-if="formCheckSynchro">
                <div class="form-check mb-3">
                  <select class="form-select" v-model="filterByYear">
                    <option v-for="item in years" :value="item" :key="item"> {{ item }}</option>
                  </select>
                </div>      
              </div>
              <div class="col-sm-auto" v-if="formCheckSynchro">
                <div class="form-check mb-3">
                  <button type="button" class="btn btn-magenta" @click="synchroApp">
                      <i class="mdi mdi-swap-horizontal align-bottom me-1"></i>
                        Sincronizzazione dati NAS-APP
                    </button>
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="card-body border-bottom-dashed border-bottom mb-20">
            <form>
              <div class="row rowSearchFormBGce g-4 mb-5">
                <div class="col-lg-6 col-md-12">
                  <div class="">
                    <div class="search-box ms-2">
                      <input type="text" class="form-control" placeholder="Inserisci termine di ricerca..." v-model="search" @input="resetQuery()" />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <button type="button" class="btn btn-secondary w-100" @click="getFilter()" >
                    <i class="mdi mdi-search-web me-1 align-bottom"></i>
                      Cerca
                    </button>
                </div>
                <div class="col-lg-2 col-md-12">
                 
                </div>
                <div class="col-lg-2 col-md-12">

                </div>
                <div class="col-lg-1 col-md-12">

                </div>
              </div>
              <!--end row-->
            </form>
          </div>
            <div class="card-header">
              <div class="row align-items-center mb-5">
                <div class="col-lg-8 col-md-12 ">
                  
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        Referenze
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-2 col-md-12 ">
                  </div>
                <div class="col-lg-2 col-md-12 ">
                  <template v-if="exportLabelsButton==true">
                    <b-button variant="primary" class="btn-label waves-effect waves-light"  >
                      <i class="ri-printer-line label-icon align-middle fs-16 me-2"></i>
                      <a :href="`${UrlServer}docugen/download-product-code/${base64Str}`" class="text-white">
                     
                        Esporta Etichette
                      </a> 
                    </b-button>
                  </template>
                  <div id="selection-element">
                    <div class="my-n1 d-flex align-items-center text-muted">

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card header -->
            <div class="card-body mt-2">
                <div>
                
                <div class="mb-5">
                 <template  v-if="responseListES">
                    <template v-if="responseListES.nonExistentData.length>0">
                      <b-alert show variant="danger">
                        <strong> Attenzione: </strong> ci sono ancora <b>{{ responseListES.nonExistentData.length }}</b> articoli che non sono stati inviati inviati al gestionale ESolver!
                      </b-alert>
                    </template>
                 </template>
                 
                </div>
                <div class="d-flex tRow justify-content-end mt-3 mb-20" v-if="n_results > perPage">
                    <div class="col-lg-2 col-md-12 text-right">
                        <label class="pt-2">Risultati per pagina:</label>
                    </div>        
                    <div class="col-lg-1 col-md-12 ">
                        <div class="pl-10 pr-20">
                            <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <template v-for="index in paginated.total_pages" :key="index">
                                <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }"  v-if="index<5">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                            </template>

                        
                        </ul>
                       
                        <template v-if="page>=5">
                            ...
                            <ul class="pagination listjs-pagination mb-0" >
                                <li class="active"  >
                                <a class="page" href="javascript:void(0)">{{ page}}</a>
                            </li>
                            
                        </ul>
                        </template>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
              </div>
                  <div class="table-card mb-1">
                    <div class="row thRow  ">
                    <div class="col-md-12 col-lg-7 text-left">
                       Nome
                    </div>
                    <div class="col-md-12 col-lg-1 text-left">
                      Tipologia
                    </div>
                    <div class="col-md-12 col-lg-1 text-left">
                      Dim. (MB)
                    </div>
                    <div class="col-md-12 col-lg-1 text-left">
                      Creazione
                    </div>   
                    <div class="col-md-12 col-lg-1 text-left">
                      Agg.to
                    </div>
                                
                    <div class="col-md-12 col-lg-1 text-left">
                        Visualizza
                    </div>
                </div>
                <div class="row list tRow form-check-all d-flex  " v-for=" (item, index) of responseList" :key="index">
                  <div class="col-md-12 col-lg-7 text-left">
                    <template  v-if="item.contentType=='application/directory'">
                        <strong>{{ extractBaseDir(item.name) }}</strong>
                    </template>
                    <template v-else>
                        <strong>{{ extractFilename(item.name) }} </strong>
                    </template>
                   <br>
                 
                    <ul class="list-inline hstack ">
                        ><li class="list-inline-item edit"  v-for="item in viewPath(item.name)" :key="item">
                            <template v-if="item!=''">
                                ><a href="javascript:void(0)" @click="searchByFolder(item)">
                                <img
                                    src="@/assets/images/icon-folder.png"
                                    alt="Cartella"
                                    class="img-fluid img-fluid-box-mini"
                                    />
                                {{ item }}</a> 
                            </template>

                        </li>
                      </ul>
                
                    </div>
                  <div class="col-md-12 col-lg-1 text-left" >
                    <template  v-if="item.contentType=='application/pdf'">
                        <a href="javascript:void(0)" @click="getFilePath(item.name,index)">
                            <img
                                src="@/assets/images/icon-pdf.png"
                                alt="PDF DOC"
                                class="img-fluid img-fluid-box"
                                />
                        </a>

                    </template>
                    <template  v-else-if="item.contentType=='application/x-www-form-urlencoded;charset=UTF-8'">
                        <img
                            src="@/assets/images/icon-main-dir.png"
                            alt="Cartella"
                            class="img-fluid img-fluid-box"
                            />
                    </template>
                    <template  v-else-if="item.contentType=='application/directory'">
                        <img
                            src="@/assets/images/icon-folder.png"
                            alt="Cartella"
                            class="img-fluid img-fluid-box"
                            />
                    </template>
                    <template  v-else-if="item.contentType=='application/vnd.ms-excel'">
                        <a href="javascript:void(0)" @click="getFilePath(item.name,index)">

                        <img
                            src="@/assets/images/icon-excel.png"
                            alt="File Excel"
                            class="img-fluid img-fluid-box"
                            />
                            </a>
                    </template>
                    <template  v-else-if="item.contentType=='application/msword'">
                        <a href="javascript:void(0)" @click="getFilePath(item.name,index)">
    
                        <img
                            src="@/assets/images/icon-word.png"
                            alt="File Excel"
                            class="img-fluid img-fluid-box"
                            />
                        </a>
                    </template>
                    <template  v-else-if="item.contentType=='image/png'">
                        <a href="javascript:void(0)" @click="getFilePath(item.name,index)">

                        <img
                            src="@/assets/images/icon-png.png"
                            alt="File PNG"
                            class="img-fluid img-fluid-box"
                            />
                        </a>
                        </template>
                    
                    <template  v-else-if="item.contentType=='image/jpeg'">
                        <a href="javascript:void(0)" @click="getFilePath(item.name,index)">

                        <img
                            src="@/assets/images/icon-jpg.png"
                            alt="File JPEG"
                            class="img-fluid img-fluid-box"
                            />
                        </a>
                        </template>
                    <template  v-else-if="item.contentType=='text/plain'">
                        <a href="javascript:void(0)" @click="getFilePath(item.name,index)">

                        <img
                            src="@/assets/images/icon-txt.png"
                            alt="File Excel"
                            class="img-fluid img-fluid-box"
                            />
                        </a>
                        </template>
                    <template  v-if="item.name.endsWith('.xlsx')==true">
                        <a href="javascript:void(0)" @click="getFilePath(item.name,index)">

                        <img
                            src="@/assets/images/icon-xlsx.png"
                            alt="File Xlsx"
                            class="img-fluid img-fluid-box"
                            />
                        </a>
                        </template>
                    <template  v-if="item.name.endsWith('.dwg')==true">
                        <a href="javascript:void(0)" @click="getFilePath(item.name,index)">

                        <img
                            src="@/assets/images/icon-dwg.png"
                            alt="File DWG"
                            class="img-fluid img-fluid-box"
                            />
                        </a>
                        </template>
                    <template  v-if="item.name.endsWith('.docx')==true">
                        <a href="javascript:void(0)" @click="getFilePath(item.name,index)">

                        <img
                            src="@/assets/images/icon-docx.png"
                            alt="File Docx"
                            class="img-fluid img-fluid-box"
                            />
                        </a>
                        </template>
                    <template  v-if="item.contentType=='' || item.contentType==false">
                        <img
                            src="@/assets/images/icon-nd.png"
                            alt="Estensione non riconosciuta"
                            class="img-fluid img-fluid-box"
                            />
                    </template>
                    <template  v-if="item.name.endsWith('.p7m')==true">
                        <a href="javascript:void(0)" @click="getFilePath(item.name,index)">

                        <img
                            src="@/assets/images/p7m.png"
                            alt="File Firmato Digitalmente"
                            class="img-fluid img-fluid-box"
                            />
                        </a>
                        </template>
                    
                  </div>
                  <div class="col-md-12 col-lg-1 text-left">
                    {{ formatKb(item.size) }} 
                  </div>
                  <div class="col-md-12 col-lg-1 text-left">
                    {{ convertDatetime(item.timeCreated) }} 
                  </div>
                  <div class="col-md-12 col-lg-1 text-left">
                    {{ convertDatetime(item.updated) }} 
                  </div>
                  <div class="col-md-12 col-lg-1 text-left ">
                    <ul class="list-inline hstack gap-2 mb-0">
                       <template v-if="item.resp!=''">
                        <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Visualizza" v-if="item.contentType!='application/directory'">
                            <a href="javascript:void(0)" @click="getFilePath(item.name,index)">
                                Vedi
                            </a>

                          </li>
                       </template>


                    </ul>
                  </div>
                </div>

              </div>
              <div class="d-flex tRow justify-content-end mt-3 mb-5" v-if="n_results > perPage">
                    <div class="col-lg-2 col-md-12 text-right">
                        <label class="pt-2">Risultati per pagina:</label>
                    </div>        
                    <div class="col-lg-1 col-md-12 ">
                        <div class="pl-10 pr-20">
                            <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <template v-for="index in paginated.total_pages" :key="index">
                                <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }"  v-if="index<5">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                            </template>

                        
                        </ul>
                       
                        <template v-if="page>=5">
                            ...
                            <ul class="pagination listjs-pagination mb-0" >
                                <li class="active"  >
                                <a class="page" href="javascript:void(0)">{{ page}}</a>
                            </li>
                            
                        </ul>
                        </template>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
              </div>
            <!--<pre>{{ responseList }}</pre>-->
              </div>
            </div>
            
        </div>
  </div>
</template>